import useCustomer from "~/composables/useCustomer";
import { callWithNuxt, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp()
    const {data, status} = useAuth();
    const redirectToAfterSignedIn = useCookie('redirectTo')

    if (status?.value === 'authenticated' && data?.value?.customer_type === 0) {
        return callWithNuxt(nuxtApp, () => navigateTo(redirectToAfterSignedIn.value))
    }
})
